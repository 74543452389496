body {
    margin: 60px 10px;
}

.content {

    padding: 20px;

    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    -moz-border-radius: 12px;
    -khtml-border-radius: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;

    background: #f6f7f9;
}
