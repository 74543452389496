/*
RESET ALL ELEMENTS FOR COMPATIBILITY: DO NOT EDIT
http://meyerweb.com/eric/tools/css/reset/
---------------------------------------------------------------------------------------------------- */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    font-size: inherit;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
}
body { line-height: 1; }
ol,
ul { list-style: none; }
blockquote,
q { quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after { content: ""; content: none; }

/* focus styles defined in typography
:focus { outline: 0; } */
/* edit in typography if using inserts */
ins { text-decoration: none; }
del { text-decoration: line-through; }

/* tables still need 'cellspacing="0"' in the markup */
table { border-collapse: collapse; border-spacing: 0; }

/**** END RESET STYLES ****/

code {
    white-space: pre;
}

/*
TYPOGRAPHY BASELINE
---------------------------------------------------------------------------------------------------- */
html {
    font-size: 10px;
    height: 100%;
}

body {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.5;
    font-family: "Proxima Nova Soft W03 Reg", Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif;
    color: #666666;
    height: 100%;
}

/* html,form { height: 100%; } */

html * { -moz-box-sizing: border-box; box-sizing: border-box; }

a:focus {
    outline: 1px dotted #ffffff;
}

input.field:focus,
input.configField:focus {
    border: 1px solid #0081c6;
}

hr {
    border: 0 #cccccc solid;
    border-top-width: 1px;
    clear: both;
    height: 0;
}

/*
Utilities
---------------------------------------------------------------------------------------------------- */
.visuallyHidden {
    position: fixed;
    top: -9999px;
    left: -9999px;
}

/* `Headings
---------------------------------------------------------------------------------------------------- */

h1 {
    font-size: 23px;
}

h2 {
    font-size: 21px;
}

h3 {
    font-size: 19px;
}

h4 {
    font-size: 17px;
}

h5 {
    font-size: 15px;
}

h6 {
    font-size: 13px;
}

/* `Spacing
---------------------------------------------------------------------------------------------------- */

ol {
    list-style: decimal;
}

ul {
    list-style: disc;
}

li {
    margin-left: 30px;
}

p,
dl,
hr,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
pre,
table,
address,
fieldset {
    margin-bottom: 20px;
}

/**** END TYPOGRAPHY ****/

/********************************** PANEL FUNCTION ************************/
/* always define position.  never define z-index */
/* anytime top: or left: is defined, position must be absolute */
/* top: needs to be large enough to hide the largest panel.  but not too big so that the first time the panel shows it takes a while to "slide" down */
div.modalPagePanel {
    position: absolute;
    top: -1000px;
}

div.modalInternalPanel {
    position: relative;
}

div.modalInternalPanelSlider {
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
}

div.screenOverlay {
    position: absolute;
    left: -1px;
    top: -1px;
    width: 1px;
    height: 1px;
    background-color: #000000;
}


/* Angular animations **/

.animate-new-yellow {
    -webkit-transition: all linear 0.5s;
    transition: all linear 0.5s;
}

.animate-new-yellow.ng-leave {
    display: none;
}

.animate-new-yellow.ng-enter {
    background-color: none;
}

.animate-new-yellow.ng-enter.ng-enter-active {
    background-color: #ffffcc;
}

.animate-show-fadein.ng-hide-add,
.my-element.ng-hide-remove {
    /* this is required as of 1.3x to properly
	    apply all styling in a show/hide animation */
    transition: 0s ease-in-out all;
}

.animate-show-fadein.ng-hide-add-active,
.animate-show-fadein.ng-hide-remove-active {
    /* the transition is defined in the active class */
    transition: 0.5s ease-in-out all;
}
.animate-show-fadein.ng-hide-remove { opacity: 0; }
.animate-show-fadein.ng-hide-remove.ng-hide-remove-active { opacity: 1; }
.animate-show-fadein.ng-hide-add { transition: 0.1s ease-in-out all; opacity: 0; }
.animate-show-fadein.ng-hide-add.ng-hide-add-active { opacity: 0; }

/* angular-ui modal sizes */
.xs-modal .modal-dialog {
    width: 300px;
}

.s-modal .modal-dialog {
    width: 500px;
}

.med-modal .modal-dialog {
    width: 700px;
}

/* the next two class changes make the edit modal slide down and cover the full screen and lock at the top */
.full-modal .modal-content {
    border: none;
    border-radius: 0;
}

.full-modal .modal-dialog {
    width: 100%;
    margin: 0;
}

.large-modal .modal-dialog {
    width: 90%;
    min-width: 1020px;
    margin: 0 auto;
    transform: translate(-50%, -50%);
}

.img-modal .modal-dialog {
    width: auto;
    max-width: 900px;
}

/********************************** DEBUG ************************/
#jspDebugDiv,
#pageGlobalsDebugDiv {
    margin: 0 0 0 10px;
}

table.debug td {
    padding: 3px;
    font-size: 10px;
    background-color: #e9eff3;
    border-bottom: 1px solid #ffffff;
}

#jspDebugDiv caption {
    font-size: 11px;
    color: #677b87;
}

#pageLogDiv {
    width: 500px;
    height: 100px;
    overflow: auto;
    background-color: #e9eff3;
    font-size: 9px;
    color: #333333;
}

table.debugObject {
    border: 1px solid #cccccc;
}

table.debugObject td {
    padding: 3px;
    font-size: 10px;
    background-color: #e9eff3;
    border-bottom: 1px solid #ffffff;
    vertical-align: top;
}

table.debugObject td.javatypename {
    font-size: 12px;
    color: #000000;
    vertical-align: top;
    background-color: #b3c4cf;
}

